<template>
  <b-row>
    <b-col cols="3">
      <router-link to="/transactions">
        <div class="bg-primary curved-edge shadow-sm text-white p-3">
          <div class="row no-gutters justify-content-between">
            <div>
              <h1 class="text-30">{{ transactions.pending }}</h1>
              <p class="text-12 text-uppercase">Pending Transactions</p>
            </div>
            <img src="@/assets/images/truck.svg" alt="truck" width="53" height="53">
          </div>
        </div>
      </router-link>
    </b-col>
    <b-col cols="3">
      <router-link to="/transactions">
        <div class="bg-primary curved-edge shadow-sm text-white p-3">
          <div class="row no-gutters justify-content-between">
            <div>
              <h1 class="text-30">{{ transactions.paid }}</h1>
              <p class="text-12 text-uppercase">Paid Transactions</p>
            </div>
            <img src="@/assets/images/cash.svg" alt="truck" width="53" height="53">
          </div>
        </div>
      </router-link>
    </b-col>
    <b-col cols="3">
      <router-link to="/transactions">
        <div class="bg-primary curved-edge shadow-sm text-white p-3">
          <div class="row no-gutters justify-content-between">
            <div>
              <h1 class="text-30">{{ transactions.pending }}</h1>
              <p class="text-12 text-uppercase">Declined Transactions</p>
            </div>
            <img src="@/assets/images/card.svg" alt="truck" width="53" height="53">
          </div>
        </div>
      </router-link>
    </b-col>
    <b-col cols="3">
      <div class="bg-suspend curved-edge shadow-sm text-white text-right p-3">
        <h1 class="text-30">{{ users.suspended }}</h1>
        <p class="text-12 text-uppercase">Suspended Users</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    users: {
      type: Object,
      default () {
        return {}
      }
    },
    transactions: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
</style>
