<template>
  <b-card class="af-card mt-4">
    <h2 class="page-title text-14 font-weight-bold text-uppercase mb-4">Recent Conversions</h2>
    <!-- table -->
    <b-table
      id="af-table"
      :items="conversions"
      :fields="fields"
      hover
      responsive
      borderless
    >

      <template v-slot:head()="data">
        <span class="text-uppercase font-weight-bold">{{ data.label }}</span>
      </template>

      <template v-slot:head(status)="data">
        <span class="d-none">{{ data.value }}</span>
      </template>

      <template v-slot:head(fullname)>
        <span class="text-uppercase font-weight-bold">Fullname</span>
      </template>

      <template v-slot:cell(number)="data">
        <span>0{{ data.value }}</span>
      </template>

      <template v-slot:cell(fullname)="data">
        <div class="row align-items-center no-gutters">
          <div class="text-uppercase mr-2">
            <span class="initials">{{ computeInitials(data.value) }}</span>
          </div>
          <div>
            <p class="font-weight-bold mb-0">{{ data.value }}</p>
            <p class="text-muted text-14 mb-0">{{ computeDate(data.item.createdAt) }}</p>
          </div>
        </div>
      </template>

      <template v-slot:cell(network_name)="data">
        <span class="font-weight-bold">{{ data.value }}</span>
      </template>

      <template v-slot:cell(amount)="data">
        <span class="font-weight-bold">{{ data.value | format-currency }}</span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { approveConversion } from '@/apis/conversions'

export default {
  props: {
    conversions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      items: [],
      fields: [
        'fullname',
        'network_name',
        'amount'
      ]
    }
  },
  methods: {
    computeDate (date) {
      const d = new Date(date)
      return d.toLocaleDateString().replace(/\//gi, '-')
    },
    computeInitials (name) {
      const names = name.split(' ')
      let initials = ''
      for (let i = 0; i < names.length; i++) {
        initials += names[i].charAt(0)
      }
      return initials
    },
    async approveConversion (id) {
      try {
        await approveConversion(id)
        this.$bvToast.toast('Conversion approved', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.$emit('conversion-approved')
      } catch (error) {
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.initials {
  background-color: #3FBBC2;
  color: white;
  padding: 13px;
  border-radius: 50%;
}
.page-item.active .page-link {
  background-color: #F38D04;
  color: #fff;
}
</style>
