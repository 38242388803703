import axios from 'axios'
import router from '../router'
import store from '../store'

axios.interceptors.request.use(config => {
  store.commit('setProcessing', true)
  return config
}, error => {
  Promise.reject(error)
})

axios.interceptors.response.use((res) => {
  store.commit('setProcessing', false)
  return res
}, (error) => {
  store.commit('setProcessing', false)
  if (error.response.status === 401 || error.response.status === 403) {
    return router.push('/login')
  }
  return Promise.reject(error)
})

export const get = (resource, params) => axios.get(resource, params)

export const post = (resource, params) => axios.post(resource, params)

export const put = (resource, params) => axios.put(resource, params)

export const del = (resource, params) => axios.delete(resource, params)
