<template>
  <main class="container-fluid">
    <Overview :users="dashboard.users" :transactions="dashboard.transactions" />
    <b-row class="my-4">
      <b-col cols="8">
        <Users :users="dashboard.users" />
        <Conversion :conversions="dashboard.recentConversions" />
      </b-col>
      <b-col cols="4">
        <Wallet :balances="dashboard.walletOverview" />
      </b-col>
    </b-row>
    <Transactions :transactions="dashboard.recentTransactions" />
  </main>
</template>

<script>
import Overview from '../components/home/Overview.vue'
import Users from '../components/home/Users.vue'
import Conversion from '../components/home/Conversion.vue'
import Wallet from '../components/home/Wallet.vue'
import Transactions from '../components/home/Transactions.vue'

import { getDashboard } from '@/apis/dashboard'

export default {
  metaInfo: {
    title: 'Dashboard',
    titleTemplate: '%s | AirtimeFlip'
  },
  components: {
    Overview,
    Users,
    Conversion,
    Wallet,
    Transactions
  },
  data () {
    return {
      dashboard: {
        recentConversions: [],
        recentTransactions: [],
        transactions: {},
        users: {},
        walletOverview: {}
      }
    }
  },
  async created () {
    try {
      const { data } = await getDashboard()
      this.dashboard = data.data
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
