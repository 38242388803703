<template>
  <div class="bg-black text-white curved-edge shadow-sm p-3">
    <h1 class="text-14 text-uppercase font-weight-bold mb-4">Wallet Overview</h1>
    <div class="row no-gutters justify-content-between mb-4">
      <p class="text-14 text-light-grey text-uppercase">Total Data <br>Sold</p>
      <p>₦ {{ balances.totalDataSold | format-currency }}</p>
    </div>
    <div class="row no-gutters justify-content-between mb-4">
      <p class="text-14 text-light-grey text-uppercase">Total Airtime <br>Converted</p>
      <p>₦ {{ balances.totalAirtimeConverted | format-currency }}</p>
    </div>
    <div class="row no-gutters justify-content-between mb-4">
      <p class="text-14 text-light-grey text-uppercase">Total Wallet <br>Balance</p>
      <p>₦ {{ balances.totalWalletBalance | format-currency }}</p>
    </div>
    <div class="row no-gutters justify-content-between mb-4">
      <p class="text-14 text-light-grey text-uppercase">Total Wallet <br>Transfer</p>
      <p>₦ {{ balances.totalWalletTransfer | format-currency }}</p>
    </div>
    <div class="row no-gutters justify-content-between mb-5">
      <p class="text-14 text-light-grey text-uppercase">Total Amount <br>Withdrawn</p>
      <p>₦ {{ balances.totalAmountWithdrawn | format-currency }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    balances: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
