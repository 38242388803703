<template>
  <b-card class="af-card curved-edge shadow-sm">
    <h2 class="font-weight-bold text-14 text-uppercase mb-4">Recent Transactions</h2>
    <!-- table -->
    <b-table
      id="af-table"
      :items="filteredTransactions"
      :fields="fields"
      :busy="isBusy"
      hover
      responsive
      show-empty
      borderless
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner small class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #empty>
        <p class="text-center text-muted">No data found</p>
      </template>

      <template v-slot:head()="data">
        <span class="text-uppercase font-weight-bold">{{ data.label }}</span>
      </template>

      <template v-slot:head(type)>
        <span class="font-weight-bold text-uppercase">Transaction</span>
      </template>

      <template v-slot:head(reference)>
        <span class="font-weight-bold text-uppercase">Transaction ID</span>
      </template>

      <template v-slot:head(createdAt)>
        <span class="font-weight-bold text-uppercase">Date</span>
      </template>

      <template v-slot:cell(createdAt)="data">
        <span>{{ formatDate(data.value) }}</span>
      </template>

      <template v-slot:head(updatedAt)>
        <span class="font-weight-bold text-uppercase">Time</span>
      </template>

      <template v-slot:cell(updatedAt)="data">
        <span class="text-uppercase">{{ formatTime(data.value) }}</span>
      </template>

      <template v-slot:cell(amount)="data">
        <span class="text-success title text-uppercase">₦ {{ data.value | format-currency }}</span>
      </template>

      <template v-slot:cell(status)="data">
        <span v-if="data.value === 'completed'" class="text-success">Completed</span>
        <span v-if="data.value === 'pending'" class="text-warning">Pending</span>
        <span v-if="data.value === 'failed'" class="text-danger">Failed</span>
        <span v-if="data.value === 'declined' || ''" class="text-danger">Declined</span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    transactions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isBusy: false,
      filteredTransactions: [],
      fields: [
        'createdAt',
        'updatedAt',
        'type',
        'reference',
        'amount',
        'status'
      ],
      options: [
        { value: '', text: 'Sort by' },
        { value: 'completed', text: 'Completed' },
        { value: 'pending', text: 'Pending' },
        { value: 'declined', text: 'Declined' },
        { value: 'failed', text: 'Failed' }
      ]
    }
  },
  watch: {
    transactions (val) {
      this.filteredTransactions = [...this.transactions]
    }
  },
  methods: {
    formatDate (date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    formatTime (time) {
      return format(new Date(time), 'h:mm a')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
