<template>
  <b-card class="af-card curved-edge shadow-sm">
    <h1 class="text-14 font-weight-bold text-uppercase mb-4">Users</h1>
    <b-row>
      <b-col cols="4" class="border-right">
        <router-link to="/users">
          <h2 class="text-30">{{ users.total }}</h2>
          <p class="text-uppercase text-secondary text-12">Total Users</p>
        </router-link>
      </b-col>
      <b-col cols="4" class="border-right">
        <router-link to="/validation">
          <h2 class="text-30">{{ users.verified }}</h2>
          <p class="text-uppercase text-secondary text-12">Verified Users</p>
        </router-link>
      </b-col>
      <b-col cols="4">
        <router-link to="/validation">
          <h2 class="text-30">{{ users.unverified }}</h2>
          <p class="text-uppercase text-secondary text-12">Un-Verified Users</p>
        </router-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    users: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: inherit;
  text-decoration: none;
}
</style>
